export const apiUrl = 'https://api.stage.sanisaver.com/api'

export const apiRoutes = {
  login: '/login',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  //* using url given from BE
  resetPassword: '',

  violations: '/violations',
  violationDetails: (id: ApiID) => `/violations/${id}`,

  //todo: replace
  violationSendReopenRequest: '/violations/reopen-default',
  violationSendOnlineHearing: 'violations/submit-hearing-form',

  violationsInfractionCodes: '/violations/infraction-codes',
  violationsHearingResults: '/violations/hearing-results',
  violationsCaseStatuses: '/violations/case-statuses',
  violationActivity: (id: ApiID) => `violations/activity/${id}`,

  payments: '/transactions',
  paymentsStatuses: '/transactions/statuses',
  incomeStats: '/transactions/income-stats',

  buildings: '/buildings',

  infractionCodes: '/infraction-codes',
  infractionCodeDetails: (id: ApiID) => `/infraction-codes/${id}`,
  infractionCodeUpdate: (id: ApiID) => `/infraction-codes/${id}`,
  quizzes: () => `/quizzes/`,
  quizzesUpdate: (id: ApiID) => `/quizzes/${id}`,

  users: '/users',
  userDetails: (id: ApiID) => `/users/${id}`,
}

export const defaultPerPage = 10

export const loadStaticsInterval = 1000 * 60 * 60 //* one hour

export default {
  apiUrl,
  apiRoutes,
  defaultPerPage,
  loadStaticsInterval,
}
